import requireComponent from "./requireComponent"

export default {
  install(app) {
    app.component("nested-connection-item", requireComponent("@/components/connections/NestedConnectionItem.vue"))
    app.component("page", requireComponent("@/components/PageContainer.vue"))
    app.component("page-header", requireComponent("@/components/PageHeader.vue"))
    app.component("header-button", requireComponent("@/components/PageHeaderButton.vue"))
    app.component("search-input", requireComponent("@/components/SearchInput.vue"))
    app.component("sort-button", requireComponent("@/components/SortButton.vue"))
    app.component("avatar", requireComponent("@/components/Avatar.vue"))
    app.component("fa-icon", requireComponent("@/components/FaIcon.vue"))
    app.component("ps-data-table", requireComponent("@/components/Table/PsDataTable.vue"))
    app.component("ps-table", requireComponent("@/components/Table/PsTable.vue"))
    app.component("ps-header-cell", requireComponent("@/components/Table/PsHeaderCell.vue"))
    app.component("ps-table-row", requireComponent("@/components/Table/PsTableRow.vue"))
    app.component("ps-row-cell", requireComponent("@/components/Table/PsRowCell.vue"))
    app.component("char-counter", requireComponent("@/components/form/CharacterCounter.vue"))
    app.component("form-dialog", requireComponent("@/components/form/FormDialog.vue"))
    app.component("form-resource-dialog", requireComponent("@/components/form/FormResourceDialog.vue"))
    app.component("nice-date-picker", requireComponent("@/components/NiceDatePicker.vue"))
    app.component("form-section", requireComponent("@/components/form/Section.vue"))
    app.component("form-row", requireComponent("@/components/form/Row.vue"))
    app.component("empty-state", requireComponent("@/components/EmptyState.vue"))
    app.component("nice-bar", requireComponent("@/components/NiceBar.vue"))
    app.component("spinner", requireComponent("@/components/Spinner.vue"))
    app.component("nice-select", requireComponent("@/components/NiceSelect.vue"))
    app.component("nice-select-virtualized", requireComponent("@/components/NiceSelectVirtualized.vue"))
    app.component("tag-select", requireComponent("@/components/TagSelect.vue"))
    app.component("nice-alert", requireComponent("@/components/NiceAlert.vue"))
    app.component("nice-pagination", requireComponent("@/components/NicePagination.vue"))
    app.component("nice-textarea", requireComponent("@/components/NiceTextarea.vue"))
    app.component("nice-checkbox", requireComponent("@/components/NiceCheckbox.vue"))
    app.component("nice-dropdown", requireComponent("@/components/NiceDropdown.vue"))
    app.component("nice-popover", requireComponent("@/components/NicePopover.vue"))
    app.component("nice-upload", requireComponent("@/components/NiceUpload.vue"))
    app.component("nice-label", requireComponent("@/components/NiceLabel.vue"))
    app.component("nice-cascader", requireComponent("@/components/NiceCascader.vue"))
    app.component("nice-radio-group", requireComponent("@/components/NiceRadioGroup.vue"))
    app.component("nice-radio", requireComponent("@/components/NiceRadio.vue"))
    app.component("nice-switch", requireComponent("@/components/NiceSwitch.vue"))
    app.component("nice-slider", requireComponent("@/components/NiceSlider.vue"))
    app.component("nice-progress", requireComponent("@/components/NiceProgress.vue"))
    app.component("nice-tooltip", requireComponent("@/components/NiceTooltip.vue"))
    app.component("nice-badge", requireComponent("@/components/NiceBadge.vue"))
    app.component("nice-divider", requireComponent("@/components/NiceDivider.vue"))
    app.component("nice-input", requireComponent("@/components/NiceInput.vue"))
    app.component("nice-button", requireComponent("@/components/NiceButton.vue"))
    app.component("nice-button-group", requireComponent("@/components/NiceButtonGroup.vue"))
    app.component("nice-tag", requireComponent("@/components/NiceTag.vue"))
    app.component("nice-dialog", requireComponent("@/components/NiceDialog.vue"))
    app.component("dropdown", requireComponent("@/components/Dropdown.vue"))
    app.component("dropdown-section", requireComponent("@/components/DropdownSection.vue"))
    app.component("dropdown-item", requireComponent("@/components/DropdownItem.vue"))
    app.component("list-item", requireComponent("@/components/ListItem.vue"))
    app.component("ps-button", requireComponent("@/components/PsButton.vue"))
    app.component("tip", requireComponent("@/components/Tip.vue"))
    app.component("popover-filter-row", requireComponent("@/components/PopoverFilterRow.vue"))
    app.component("client-select", requireComponent("@/components/ClientSelect.vue"))
    app.component("property-select", requireComponent("@/components/PropertySelect.vue"))
    app.component("project-select", requireComponent("@/components/ProjectSelect.vue"))
    app.component("db-select", requireComponent("@/components/DbSelect.vue"))
    app.component("broker-department-select", requireComponent("@/components/BrokerDepartmentSelect.vue"))
    app.component("snippet-select", requireComponent("@/components/SnippetSelect.vue"))
    app.component("sidebar-menu", requireComponent("@/components/sidebar-menu/SidebarMenu.vue"))
    app.component("tooltip", requireComponent("@/components/NiceTooltip.vue"))
    app.component("select-popover", requireComponent("@/components/SelectPopover.vue"))
    app.component("dv-row", requireComponent("@/components/detail-view/Row.vue"))
    app.component("card", requireComponent("@/components/Card.vue"))
    app.component("currency-input", requireComponent("@/components/CurrencyInput.vue"))
    app.component("clipboard-copy", requireComponent("@/components/ClipboardCopy.vue"))
    app.component("accept-contact", requireComponent("@/components/client/AcceptContact.vue"))
    app.component("gdpr-status", requireComponent("@/components/client/GdprStatus.vue"))
    app.component("clients-actions-dropdown", requireComponent("@/components/client/ActionsDropdown.vue"))
    app.component("base-field", requireComponent("@/components/detail-view/BaseField.vue"))
    app.component("settings-container", requireComponent("@/components/SettingsContainer.vue"))
    app.component("settings-section", requireComponent("@/components/SettingsSection.vue"))
    app.component("settings-list", requireComponent("@/components/settings/SettingsList.vue"))
    app.component("settings-single", requireComponent("@/components/settings/SettingsSingle.vue"))
    app.component("settings-service", requireComponent("@/components/settings/SettingsService.vue"))
    app.component("nestable-list-item", requireComponent("@/components/settings/NestableListItem.vue"))
    app.component("bubble", requireComponent("@/components/Bubble.vue"))
    app.component("hover-actions", requireComponent("@/components/HoverActions.vue"))
    app.component("hover-action", requireComponent("@/components/HoverAction.vue"))
    app.component("file-icon", requireComponent("@/components/FileIcon.vue"))
    app.component("rich-textarea", requireComponent("@/components/RichTextarea.vue"))
    app.component("color-picker", requireComponent("@/components/ColorPicker.vue"))
    app.component("date-cell", requireComponent("@/components/DateCell.vue"))
    app.component("alert", requireComponent("@/components/Alert.vue"))
    app.component("broker-avatars", requireComponent("@/components/BrokerAvatars.vue"))
    app.component("upload-area", requireComponent("@/components/UploadArea.vue"))
    app.component("pill", requireComponent("@/components/Pill.vue"))
    app.component("main-sidebar", requireComponent("@/components/sidebar/MainSidebar.vue"))
    app.component("admin-sidebar", requireComponent("@/components/sidebar/AdminSidebar.vue"))
    app.component("promo-highlighter", requireComponent("@/components/PromoHighlighter.vue"))
    app.component("generic-extensions-page", requireComponent("@/components/GenericExtensionsPage.vue"))
    app.component("draggable", requireComponent("@/components/Draggable.vue"))
    app.component("present-brokers", requireComponent("@/components/PresentBrokers.vue"))
    app.component("copy-to-clipboard", requireComponent("@/components/CopyToClipboard.vue"))
    app.component("whatsapp-button", requireComponent("@/components/WhatsAppButton.vue"))
  },
}
